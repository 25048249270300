<script setup lang="ts">
const { t } = useI18n()

const { formatCurrency } = useCurrency()

const businessStore = useBusinessStore()
const { isTrialing } = storeToRefs(businessStore)

const props = defineProps<{
  couponCode: string
  priceData: PreviewUpgradeSubscriptionPriceResponse | null
  isFetchingPrice: boolean
}>()
const isPercentageDiscount = computed(() => {
  return Boolean(props.priceData && props.priceData.percentOff > 0)
})
const subtotalAmountAndCurrency = computed(() => {
  if (props.priceData) return formatCurrency(props.priceData.amountSubtotal, props.priceData.currency)
  return ''
})
const discountAmountAndCurrency = computed(() => {
  if (props.priceData) return formatCurrency(props.priceData.amountDiscountApplied, props.priceData.currency)
  return ''
})
const toolTipText = computed(() => {
  return isPercentageDiscount.value
    ? t('business.coupon.subtotals.percentage_coupon_info')
    : t('business.coupon.subtotals.remaining_value_info')
})

const showPromoCodeToolTip = computed(() => {
  return isTrialing.value
})
</script>

<template>
  <div class="coupon-subtotals">
    <v-overlay :model-value="isFetchingPrice" absolute opacity="0.7" scrim="white">
      <v-progress-circular :size="24" :width="3" color="text" indeterminate />
    </v-overlay>
    <div class="d-flex subtotal-row">
      <div class="mr-auto">{{ t('business.coupon.subtotals.subtotal') }}</div>
      <div>
        <span data-cy="coupon_subtotal">{{ subtotalAmountAndCurrency }}</span>
      </div>
    </div>
    <v-divider />
    <div class="d-flex subtotal-row">
      <div class="mr-auto">
        {{ couponCode }}
        <v-tooltip v-if="showPromoCodeToolTip" location="right" max-width="165" content-class="text-center bg-primary">
          <template #activator="{ props }">
            <v-icon color="primary" :size="15" v-bind="props">custom:info</v-icon>
          </template>
          <span>{{ toolTipText }}</span>
        </v-tooltip>
      </div>
      <div>
        <span data-cy="coupon_applied_discount">- {{ discountAmountAndCurrency }}</span>
      </div>
    </div>
    <v-divider />
  </div>
</template>

<style lang="sass">
$ns: coupon-subtotals
.#{$ns}
  position: relative
  .subtotal-row
    height: 56px
    align-items: center
    font-size: 13px
  .v-divider
    border-style: dashed
  .v-icon
    vertical-align: text-top
</style>
