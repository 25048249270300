<script setup lang="ts">
import { mdiMinus, mdiPlus } from '@mdi/js'

const props = withDefaults(
  defineProps<{
    min?: number
    max?: number
    decrEnabled?: boolean
    incrEnabled?: boolean
    showDecrTooltip?: boolean
    showIncrTooltip?: boolean
    disabled?: boolean
  }>(),
  {
    min: 0,
    max: 9999,
  }
)

const inputEl = ref<{ $el: HTMLInputElement } | null>(null)

const emit = defineEmits<{ (event: 'input', value: number): void }>()

const icons = reactive({
  add: mdiPlus,
  remove: mdiMinus,
})

const inputValue = ref(props.min)
const isNumber = (event: KeyboardEvent) => {
  if (!/\d/.test(event.key)) return event.preventDefault()
}

watch(inputValue, () => {
  const value = Math.min(Math.max(props.min, inputValue.value), props.max)
  if (value !== inputValue.value) inputValue.value = value
  else emit('input', value)
})

const validateOnBlur = () => {
  const el = inputEl.value?.$el.querySelector('input')
  if (!el) return
  if (el.value === '') {
    inputValue.value = props.min
    el.value = props.min.toString()
  } else if (Number(el.value) < props.min) {
    inputValue.value = props.min
    el.value = props.min.toString()
  } else if (Number(el.value) > props.max) {
    inputValue.value = props.max
    el.value = props.max.toString()
  }
}
</script>
<template>
  <div class="number-increment">
    <v-tooltip max-width="160" content-class="text-center bg-info" location="top" :disabled="!showDecrTooltip" attach>
      <template #activator="{ props: activatorProps }">
        <div v-bind="activatorProps">
          <v-btn
            variant="text"
            icon
            data-cy="decrease_button"
            color="primary"
            :ripple="false"
            :disabled="!decrEnabled"
            @click="inputValue -= 1"
          >
            <v-icon color="#293D66">{{ icons.remove }}</v-icon>
          </v-btn>
        </div>
      </template>
      <slot name="decr" />
    </v-tooltip>

    <v-text-field
      ref="inputEl"
      v-model="inputValue"
      class="number-increment__input mx-2"
      :disabled="disabled"
      variant="outlined"
      hide-details
      @keypress="isNumber"
      @paste.prevent
      @blur="validateOnBlur"
    />

    <v-tooltip max-width="160" content-class="text-center bg-info" location="top" :disabled="!showIncrTooltip" attach>
      <template #activator="{ props: activatorProps }">
        <div v-bind="activatorProps">
          <v-btn
            variant="text"
            icon
            data-cy="increase_button"
            color="primary"
            :ripple="false"
            :disabled="!incrEnabled"
            @click="inputValue += 1"
          >
            <v-icon color="#293D66">{{ icons.add }}</v-icon>
          </v-btn>
        </div>
      </template>
      <slot name="incr" />
    </v-tooltip>
  </div>
</template>
<style lang="scss" scoped>
.number-increment {
  display: flex;
  align-items: center;

  button:disabled {
    // Counteract default Vuetify style
    background: transparent !important;
  }

  &__input {
    :deep(.v-input__control) {
      .v-field {
        position: relative;
        height: 48px;
        min-width: 52px;

        input {
          position: absolute;
          display: grid;
          place-items: center;
          min-width: 100%;
          min-height: 100%;
          top: 0;
          left: 0;
          padding: 0;
        }
      }
    }
  }
}
</style>
