<template>
  <div class="loader">
    <span />
  </div>
</template>

<style scoped>
.loader {
  width: 36px;
  height: 1rem;
  display: inline-flex;
  align-items: center;
}

.loader > span {
  left: 20px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  position: relative;
  display: inline-block;
  color: transparent;
  box-sizing: border-box;
  animation: dance 1s linear infinite alternate;
}

@keyframes dance {
  0% {
    box-shadow:
      -18px -2px #777,
      -6px 2px #777,
      6px -2px #777;
  }
  33% {
    box-shadow:
      -18px 2px #777,
      -6px -2px #777,
      6px 2px #777;
  }
  66% {
    box-shadow:
      -18px -2px #777,
      -6px 2px #777,
      6px -2px #777;
  }
  100% {
    box-shadow:
      -18px 2px #777,
      -6px -2px #777,
      6px 2px #777;
  }
}
</style>
